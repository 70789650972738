import React from "react"
import { Link } from "gatsby"

import { Button, Heading, Paragraph, Wrapper } from "components"

const SubmittedPage = () => {
  return (
    <>
      <Wrapper pt="calc(32px + 48px + 128px)">
        <Heading size={900}>Form submitted!</Heading>
        <Paragraph mt="layout.4">
          Thanks for reaching out. We will be in contact with you soon.
        </Paragraph>
        <Button
          as={Link}
          to="/"
          intent="primary"
          appearance="primary"
          mt="layout.4"
        >
          Back to Home
        </Button>
      </Wrapper>
    </>
  )
}

export default SubmittedPage
